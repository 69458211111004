<template>
  <div class="mt-1 mb-1 d-flex flex-column flex-grow-1">
    <document-toolbar
      :is-deleted="computedIsDeleted"
      :tab="tab"
      :save="doSave"
      :successMessage="computedSuccessMessage"
      :files.sync="computedAttachments"
      :show-file-manager="isEmail"
      :show-link-manager="readonly"
      :revert="doLoadMessage"
      v-on="$listeners"
      :show-save="!readonly"
      close-after-save
      :show-cancel="!readonly"
      :links.sync="computedLinks"
      :labels.sync="computedLabels"
      :show-labels="readonly"
      @close-pop-up="doLoadMessage"
      @update-links="doLoadMessage"
      hardDeleteFile
    >
      <template v-slot:prepend>
        <div class="d-flex align-center">
          <v-btn-toggle
            color="primary"
            v-if="tab.isNew"
            v-model="dataIsEmail"
            dense
            mandatory
            rounded
          >
            <v-btn>
              <icon-tooltip
                bottom
                :icon-name="'i.Email'"
                :tooltip-text="$t('t.AsEmail')"
              />
            </v-btn>
            <v-btn>
              <icon-tooltip
                bottom
                :icon-name="'i.PDF'"
                :tooltip-text="$t('t.AsPDF')"
              />
            </v-btn>
          </v-btn-toggle>
          <div
            v-if="!readonly"
            class="d-flex align-center ml-4"
          >
            <template-picker
              :templates="templates"
              @template-selected="selectedTemplateChange"
            />
            <v-select
              v-if="selectedTemplate"
              class="cultures mx-4"
              :items="cultures"
              item-text="name"
              item-value="code"
              v-model="selectedCultureCode"
              @change="selectedCultureChange"
            />
            <billing-statement-settings
              v-if="billingStatementSettings && !readonly"
              class="mr-4"
              :settings="billingStatementSettings"
              :on-apply="billingStatementSettingsChange"
              :invoice-mode="invoiceMode"
            />
          </div>
        </div>
      </template>
      <div
        v-if="readonly"
        class="message-inbox-toolbar d-flex flex-row align-center"
      >
        <v-btn
          @click="generateResponse(0)"
          class="ml-2"
        >
          <v-icon>{{$icon('i.Reply')}}</v-icon>
          <span class="ml-1">{{$t('t.Reply')}}</span>
        </v-btn>
        <v-btn
          @click="generateResponse(1)"
          class="ml-2"
        >
          <v-icon>{{$icon('i.ReplyAll')}}</v-icon>
          <span class="ml-1">{{$t('t.ReplyAll')}}</span>
        </v-btn>
        <v-btn
          @click="generateResponse(2)"
          class="ml-2"
        >
          <v-icon>{{$icon('i.Forward')}}</v-icon>
          <span class="ml-1">{{$t('t.Forward')}}</span>
        </v-btn>
        <div
          class="d-flex"
          v-if="tab.documentType === 'messages-inbox'"
        >
          <v-checkbox
            class="ml-4 mt-0"
            :label="$t('t.Processed')"
            v-model="computedIsTreated"
            hide-details
            :disabled="!dataMessageRecipientId"
          />
          <v-checkbox
            class="mx-4 mt-0"
            :label="$t('t.Read')"
            v-model="computedIsRead"
            hide-details
            :disabled="!dataMessageRecipientId"
          />
        </div>
      </div>

      <template v-slot:append>
        <v-menu
          v-if="readonly"
          offset-y
          left
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              class="ml-2"
              fab
              small
              v-on="on"
            >
              <icon-tooltip
                :icon-name="'i.DotsVertical'"
                :tooltip-text="$t('t.Actions')"
              />
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item link>
                <v-icon class="mr-4">{{$icon('i.PDF')}}</v-icon>
                <v-list-item-content @click="downloadPDF()">{{$t('t.DownloadPDF')}}</v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                v-if="!isUnassigned"
              >
                <v-icon class="mr-4">{{$icon('i.Link')}}</v-icon>
                <v-list-item-content @click="reassignMessage()">{{$t('t.Reassign')}}</v-list-item-content>
              </v-list-item>
              <v-divider v-if="canSoftDelete || canRestoreDelete || canHardDelete" />
              <v-list-item
                link
                v-if="canSoftDelete"
              >
                <v-icon class="mr-4">{{softDelete.icon}}</v-icon>
                <v-list-item-content @click="executeAction(softDelete)">{{softDelete.name}}</v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                v-if="canRestoreDelete"
              >
                <v-icon class="mr-4">{{restoreDelete.icon}}</v-icon>
                <v-list-item-content @click="executeAction(restoreDelete)">{{restoreDelete.name}}</v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                v-if="canHardDelete"
              >
                <v-icon class="mr-4">{{hardDelete.icon}}</v-icon>
                <v-list-item-content @click="showConfirmHardDelete = true">{{hardDelete.name}}</v-list-item-content>
                <!-- <v-list-item-content @click="executeAction(hardDelete)">{{hardDelete.name}}</v-list-item-content> -->
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </template>
    </document-toolbar>
    <document-in-tab class="overflow-hidden">
      <v-form
        v-model="valid"
        ref="form1"
        class="full-height"
      >
        <v-card class="main-content full-height pa-4 d-flex flex-column">
          <div
            v-if="isEmail"
            class="message-from-to d-flex flex-row"
          >
            <div class="d-flex flex-column full-width">
              <div
                class="message-from d-flex"
                v-if="readonly"
              >
                <div class="d-flex align-baseline">
                  <span class="text-caption recipient-type">{{$t('t.From')}}</span>
                  <user-picker
                    :attach="false"
                    :selection.sync="computedSender"
                    :scope="accountScope"
                    :document-types="['account-contacts', 'users']"
                    can-close-chip
                    :readonly="readonly"
                    :external-user-cache-type="externalUserCacheType"
                    :hide-email-if-user="isOutbox"
                    has-email
                    @apply="addContact($event)"
                  />
                </div>
                <v-spacer />
                <div>
                  <span>{{isOutbox?$t('t.SentOn'):$t('t.ReceivedOn')}} : {{sentOn | datetime}}</span>
                </div>
              </div>
              <div class="d-flex">
                <div class="flex-grow-1">
                  <div class="overflow-y-hidden d-flex align-baseline">
                    <span class="text-caption recipient-type">{{$t('t.To')}}</span>
                    <div class="d-flex flex-column overflow-y-auto recipients">
                      <user-picker
                        :attach="false"
                        :selection.sync="computedTo"
                        :scope="accountScope"
                        :document-types="['account-contacts', 'users']"
                        can-close-chip
                        :readonly="readonly"
                        :placeholder="userPickerPlaceholder"
                        :external-user-cache-type="externalUserCacheType"
                        :excluded-items="computedTo"
                        @apply="addContact($event)"
                        show-add-user-email
                        has-email
                      >
                        <template v-slot:append>
                          <toggle-link-unlink
                            :scope.sync="scope"
                            :tooltip-link="$t('t.RecipientsLinkedToAccount')"
                            :tooltip-unlink="$t('t.AllRecipients')"
                          />
                        </template>
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>{{$t('t.NoRecipientsAvailables')}}</v-list-item-content>
                          </v-list-item>
                        </template>
                      </user-picker>
                    </div>
                  </div>
                  <div
                    class="recipients d-flex align-baseline"
                    v-if="isCCVisible"
                  >
                    <span class="text-caption recipient-type">{{$t('t.CC')}}</span>
                    <user-picker
                      :attach="false"
                      :selection.sync="computedCc"
                      :scope="accountScope"
                      :document-types="['account-contacts', 'users']"
                      can-close-chip
                      :readonly="readonly"
                      :placeholder="userPickerPlaceholder"
                      :external-user-cache-type="externalUserCacheType"
                      :excluded-items="computedCc"
                      @apply="addContact($event)"
                      show-add-user-email
                      has-email
                    >
                      <template v-slot:append>
                        <toggle-link-unlink
                          :scope.sync="scope"
                          :tooltip-link="$t('t.RecipientsLinkedToAccount')"
                          :tooltip-unlink="$t('t.AllRecipients')"
                        />
                      </template>
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>{{$t('t.NoRecipientsAvailables')}}</v-list-item-content>
                        </v-list-item>
                      </template>
                    </user-picker>
                  </div>
                  <div
                    class="recipients d-flex align-baseline"
                    v-if="isBCCVisible"
                  >
                    <span class="text-caption recipient-type">{{$t('t.BCC')}}</span>
                    <user-picker
                      :attach="false"
                      :selection.sync="computedBcc"
                      :scope="accountScope"
                      :document-types="['account-contacts', 'users']"
                      can-close-chip
                      :readonly="readonly"
                      :placeholder="userPickerPlaceholder"
                      :external-user-cache-type="externalUserCacheType"
                      :excluded-items="computedBcc"
                      @apply="addContact($event)"
                      show-add-user-email
                      has-email
                    >
                      <template v-slot:append>
                        <toggle-link-unlink
                          :scope.sync="scope"
                          :tooltip-link="$t('t.RecipientsLinkedToAccount')"
                          :tooltip-unlink="$t('t.AllRecipients')"
                        />
                      </template>
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>{{$t('t.NoRecipientsAvailables')}}</v-list-item-content>
                        </v-list-item>
                      </template>
                    </user-picker>
                  </div>
                </div>
                <div class="d-flex flex-column nowrap justify-end">
                  <div>
                    <v-badge
                      v-if="!readonly || (readonly && dataCc.length > 0)"
                      color="info"
                      :content="isCCVisible ? 0 : dataCc.length"
                      :value="isCCVisible ? 0 : dataCc.length"
                      overlap
                    >
                      <v-btn
                        :color="isCCVisible ? 'primary': 'normal'"
                        @click="isCCVisible = !isCCVisible"
                      >
                        {{$t('t.CC')}}
                      </v-btn>
                    </v-badge>
                    <v-badge
                      v-if="!readonly"
                      color="info"
                      :content="isBCCVisible ? 0 : dataBcc.length"
                      :value="isBCCVisible ? 0 : dataBcc.length"
                      overlap
                    >
                      <v-btn
                        :color="isBCCVisible ? 'primary': 'normal'"
                        @click="isBCCVisible = !isBCCVisible"
                        class="ml-4"
                      >
                        {{$t('t.BCC')}}
                      </v-btn>
                    </v-badge>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column flex-grow-1 overflow-hidden rounded-0">
            <div class="d-flex flex-row">
              <v-text-field
                class="subject"
                :label="$t('t.Subject')"
                :readonly="readonly"
                :rules="[...subjectMaxLength]"
                :clearable="!readonly"
                @blur="setTabTitle()"
                counter="255"
                ref="subject_input"
                v-model="computedSubject"
              >
                <template v-slot:append-outer>
                  <token-picker
                    v-if="!readonly"
                    bottom
                    :attach="false"
                    :left="true"
                    :tokens="subjectTokens"
                    @token-selected="subjectTokenGenerator"
                  />
                </template>
              </v-text-field>
            </div>
            <div class="d-flex flex-row">
              <div
                id="ckEditorToolbar"
                class="mb-2"
                style="width: 540px;"
              />
              <token-picker
                v-if="!readonly && contentTokens && contentTokens.length > 0"
                bottom
                :attach="false"
                :tokens="contentTokens"
                @token-selected="contentTokenGenerator"
              />
            </div>
            <content-editor
              v-if="isEmail"
              class="email-editor"
              :content.sync="computedMessageContent"
              :readonly="readonly"
              :placeholder="$t('t.MessagePlaceholderContent')"
              @content-editor-ready="editorReady"
              @content-editor-focused="editorFocused"
              shared-toolbar-id="ckEditorToolbar"
            />
            <div
              v-else
              class="pdf-editor"
            >
              <content-editor
                class="pdf-editor-header"
                :content.sync="computedHeader"
                shared-toolbar-id="ckEditorToolbar"
                @content-editor-ready="editorReady"
                @content-editor-focused="editorFocused"
                @content-height="setHeaderHeight"
                :placeholder="$t('t.MessagePlaceholderHeader')"
                ref="header"
              />

              <content-editor
                class="delimited pdf-editor-body"
                :content.sync="computedMessageContent"
                :readonly="readonly"
                shared-toolbar-id="ckEditorToolbar"
                @content-editor-ready="editorReady"
                @content-editor-focused="editorFocused"
                @content-height="setContentHeight"
                :placeholder="$t('t.MessagePlaceholderContent')"
              />

              <content-editor
                class="pdf-editor-footer"
                :content.sync="computedFooter"
                shared-toolbar-id="ckEditorToolbar"
                @content-editor-ready="editorReady"
                @content-editor-focused="editorFocused"
                @content-height="setFooterHeight"
                :placeholder="$t('t.MessagePlaceholderFooter')"
                ref="footer"
              />
            </div>
          </div>
        </v-card>
      </v-form>
    </document-in-tab>
    <v-dialog
      v-model="showConfirmApplyTemplate"
      max-width=500
      @click:outside="cancelApplyTemplate()"
    >
      <v-card>
        <v-card-title>{{$t('t.PromptConfirmation')}}</v-card-title>
        <v-card-text>
          <span>{{$t('t.LostChangesConfirmation')}}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="doApplyTemplate()">{{$t('t.Yes')}}</v-btn>
          <v-btn
            color="primary"
            @click="cancelApplyTemplate()"
          >{{$t('t.No')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showConfirmHardDelete"
      width="clamp(30%, 400px, 100%)"
    >
      <v-card>
        <v-card-title>{{$t('t.PromptConfirmation')}}</v-card-title>
        <v-card-text class="d-flex-flex-column">
          {{$t('t.AreYouSure')}}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="executeAction(hardDelete)">
            {{$t('t.Yes')}}
          </v-btn>
          <v-btn
            color="primary"
            @click="showConfirmHardDelete = false"
          >{{$t('t.No')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Scope from '@/pages/search/controllers/scope'
import Search, { GlobalSearch } from '@/pages/search/controllers'
import tabController from '@/tabController'
import recentsController from '@/recentsController'
import themeController from '@/themeController'
import lodash from 'lodash'
import { getDoc, CacheType } from '@/wasm/pkg'

export default {
  components: {
    BillingStatementSettings: () => import('./billing-statement-settings'),
    ContentEditor: () => import('@/components/content-editor'),
    DocumentInTab: () => import('@/components/document-in-tab'),
    DocumentToolbar: () => import('@/components/document-toolbar'),
    TemplatePicker: () => import('@/components/template-picker'),
    TokenPicker: () => import('@/components/token-picker'),
    ToggleLinkUnlink: () => import('@/components/toggle-link-unlink'),
    UserPicker: () => import('@/components/user-picker'),
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  created () {
    this.originalIcon = this._tab.icon
    this.doLoadMessage()
  },
  async activated () {
    if (this._tab.isPristine) {
      await this.doLoadMessage()
    }
  },
  async mounted () {
    const [reqCultures, reqTokens, reqTemplatesAccountFullMessage, reqTemplatesReminder] = await Promise.all([
      this.$http().get('/enabledMessagingCulture'),
      this.$http().get(`/templateType/Reminder?culture=${this.currentUser.culture}`),
      this.$http().get('/template',
        {
          params: {
            templateType: 'AccountFullMessage',
            culture: this.currentUser.culture
          }
        }),
      this.$http().get('/template',
        {
          params: {
            templateType: 'Reminder',
            culture: this.currentUser.culture
          }
        })
    ])

    // prepare cultures
    const c = this.currentUser.culture
    reqCultures.data.forEach(_ => {
      this.cultures.push({
        code: _,
        name: this.$t('t.' + _)
      })
    })
    this.cultures = lodash.sortBy(this.cultures, [function (_) { return _.name }])
    const s = lodash.find(this.cultures, function (_) { return _.code === c })
    this.selectedCultureCode = s.code

    // prepare tokens
    this.subjectTokens = reqTokens.data.tokens.filter(t => !t.isDeprecated && t.allowInSubject)
    this.contentTokens = reqTokens.data.tokens.filter(t => !t.isDeprecated)

    // prepare templates
    this.templates = reqTemplatesAccountFullMessage.data.items.concat(reqTemplatesReminder.data.items)
    if (this.invoiceDocumentTemplate) {
      const invoiceTemplate = (await this.$http().get('/template',
        {
          params: {
            templateType: 'InvoiceDocumentMessage',
            culture: this.$store.getters.currentUser.culture
          }
        })).data.items

      this.templates = this.templates.concat(invoiceTemplate)
    }
    if (this.tab.templateId) {
      const t = this.templates.find(t => t.id === this.tab.templateId)
      this.selectedTemplateChange(t)
    }
  },
  data () {
    return {
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      dataPriority: 0,
      dataPageSize: null,
      dataLayout: null,
      dataMarginUnit: null,
      dataMarginTop: null,
      dataMarginRight: null,
      dataMarginLeft: null,
      dataMarginBottom: null,
      dataIsConsolidated: false,
      dataIsDunning: false,

      dataMessage: null,
      dataMessageId: null,
      dataIsTreated: false,
      dataIsRead: true,
      dataAttachments: [],
      dataLinks: [],
      dataLabels: [],
      dataInvoices: null,
      dataSubject: null,
      dataHeader: null,
      dataContent: null,
      dataFooter: null,
      dataCulture: null,
      dataSender: null,
      dataTo: [],
      dataCc: [],
      dataBcc: [],
      dataIsDeleted: false,
      dataAccountId: null,
      dataCreateAt: null,
      dataMessageRecipientId: null,
      showConfirmHardDelete: false,

      billingStatementSettings: null,
      billingStatementSettingsToApply: null,
      contentEditors: [],
      contentHeight: 86,
      contentTokens: [],
      cultures: [],
      cultureToApply: null,
      currentContentEditor: null,
      externalUserCacheType: CacheType.MessageRecipientRef,
      footerHeight: 86,
      headerHeight: 86,
      initialResponse: null,
      isBCCVisible: false,
      isCCVisible: false,
      loading: true,
      dataIsEmail: 0,
      originalIcon: null,
      processingResponse: false,
      recipientsKind: [{ kind: 'to', label: this.$t('t.To') }, { kind: 'others', label: this.$t('t.CC&BCC') }],
      required: [v => (!!v && !this.lodash.isEqual(v, {})) || this.$t('t.IsRequired')],
      responseMode: null,
      scope: 'local',
      selectedCultureCode: null,
      selectedrecipientsKind: 0,
      selectedTemplate: null,
      sender: null,
      setTreatedIsRunning: false,
      setReadIsRunning: false,
      showCC: false,
      showConfirmApplyTemplate: false,
      subjectMaxLength: [v => !v || v.length <= 255 || this.$t('t.MaxLength', { value: 255 })],
      subjectTokens: [],
      templates: [],
      templateToApply: null,
      themeController: themeController
    }
  },
  computed: {
    isEmail () {
      return this.dataIsEmail === 0
    },
    computedAttachments: {
      get () {
        return this.dataAttachments
      },
      set (v) {
        this.dataAttachments = v
        this.dataDocumentEmit()
      }
    },
    computedLinks: {
      get () {
        return this.dataLinks
      },
      set (v) {
        this.dataLinks = v
      }
    },
    computedLabels: {
      get () {
        return this.dataLabels
      },
      set (v) {
        this.dataLabels = v
      }
    },
    computedSubject: {
      get () {
        return this.dataSubject
      },
      set (v) {
        this.dataSubject = v
        this.dataDocumentEmit()
      }
    },
    computedContent: {
      get () {
        return this.dataContent
      },
      set (v) {
        this.dataContent = v
        this.dataDocumentEmit()
      }
    },
    computedFooter: {
      get () {
        return this.dataFooter
      },
      set (v) {
        this.dataFooter = v
        this.dataDocumentEmit()
      }
    },
    computedHeader: {
      get () {
        return this.dataHeader
      },
      set (v) {
        this.dataHeader = v
        this.dataDocumentEmit()
      }
    },
    computedCulture: {
      get () {
        return this.dataCulture
      },
      set (v) {
        this.dataCulture = v
        this.dataDocumentEmit()
      }
    },
    computedSender: {
      get () {
        return this.dataSender
      },
      set (v) {
        this.dataSender = v
        this.dataDocumentEmit()
      }
    },
    computedCc: {
      get () {
        return this.dataCc
      },
      set (v) {
        this.dataCc = v
        lodash.forEach(this.dataCc, (_) => {
          _.type = 1
        })
        this.dataDocumentEmit()
      }
    },
    computedBcc: {
      get () {
        return this.dataBcc
      },
      set (v) {
        this.dataBcc = v
        lodash.forEach(this.dataBcc, (_) => {
          _.type = 2
        })
        this.dataDocumentEmit()
      }
    },
    computedTo: {
      get () {
        return this.dataTo
      },
      set (v) {
        this.dataTo = v
        lodash.forEach(this.dataTo, (_) => {
          _.type = 0
        })
        this.dataDocumentEmit()
      }
    },
    accountScope () {
      return this.scope === 'local' ? Scope.account(this.accountId) : undefined
    },
    needConfirmation () {
      return this.responseMode !== null
        ? this.initialResponse !== this.dataContent
        : this.dataContent.length > 0
    },
    actionItem () {
      return {
        id: this.tab.documentId,
        type: this.tab.documentType,
        extra: { isDeleted: this.computedIsDeleted }
      }
    },
    actions () {
      return Search.getActions(this.actionItem)
    },
    restoreDelete () {
      return this.actions.get('restore-delete-message')
    },
    canRestoreDelete () {
      return this.restoreDelete?.validateRules([this.actionItem])
    },
    softDelete () {
      return this.actions.get('soft-delete-message')
    },
    canSoftDelete () {
      return this.softDelete?.validateRules([this.actionItem])
    },
    hardDelete () {
      return this.actions.get('hard-delete-message')
    },
    canHardDelete () {
      return this.hardDelete?.validateRules([this.actionItem])
    },
    computedSuccessMessage () {
      return this.isEmail ? this.$t('t.MessageSent') : this.$t('t.PdfDownloaded')
    },
    computedMessageContent: {
      get () {
        // Parse the string as html and get the body's children
        const childElementCount = new DOMParser().parseFromString(this.dataContent, 'text/html').body.childElementCount
        // If there is a content and no children, use <pre> tag to preserve text formating
        return (this.dataContent?.length && !childElementCount) ? `<pre>${this.dataContent}</pre>` : this.dataContent
      },
      set (v) {
        this.dataContent = v
        if (this.processingResponse) {
          this.initialResponse = v
          this.processingResponse = false
        }

        this.dataDocumentEmit()
      }
    },
    currentUser () {
      return this.$store.getters.currentUser
    },
    computedIsRead: {
      get () {
        return this.dataIsRead
      },
      set (v) {
        this.setRead(v)
        this.dataIsRead = v
      }
    },
    computedIsDeleted () {
      return this.dataIsDeleted
    },
    computedIsTreated: {
      get () {
        return this.dataIsTreated
      },
      set (v) {
        this.setTreated(v)
        this.dataIsTreated = v
      }
    },
    accountId () {
      return this.dataAccountId || this.$route.params.id
    },
    canShowCC () {
      return (this.readonly && this.cc.length !== 0) || this.showCC
    },
    canShowCCButton () {
      return this.readonly
        ? false
        : (this.cc.length > 0 || !this.readonly)
    },
    userPickerPlaceholder () {
      return this.scope === 'local' ? this.$t('t.RecipientsLinkedToAccount') : this.$t('t.AllRecipients')
    },
    readonly: {
      get () {
        return this._tab.isPristine && !this._tab.isNew
      },
      set (v) {
        this.$emit('update:tab', Object.assign(this.tab, { readonly: v, isPristine: v, isNew: false }))
      }
    },
    _tab: {
      get () {
        return this.tab
      },
      set (v) {
        this.$emit('update:tab', v)
      }
    },
    valid: {
      get () {
        let v = false

        if (this.isEmail) {
          v = this.dataTo.length >= 1 && (this.dataContent && this.dataContent.length > 0)
        } else {
          v = this.dataContent && this.dataContent.length > 0
        }

        this.$emit('document-can-save', { tab: this.tab, value: v })

        return v
      },
      set (v) {
        if (this.isEmail) {
          v = v && this.dataTo.length >= 1 && (this.dataContent && this.dataContent.length > 0)
        } else {
          v = v && (this.dataContent && this.dataContent.length > 0)
        }

        this.$emit('document-can-save', { tab: this.tab, value: v })
      }
    },
    sentOn () {
      return this.dataCreateAt
    },
    invoiceMode () {
      return !!this.dataInvoices?.length
    }
  },
  methods: {
    emitDocument () {
      const m = {
        attachments: this.dataAttachments,
        content: this.dataContent,
        culture: this.dataCulture,
        footer: this.dataFooter,
        header: this.dataHeader,
        invoices: this.dataInvoices,
        isDunning: this.dataIsDunning,
        links: this.dataLinks,
        labels: this.dataLabels,
        recipients: this.dataTo.concat(this.dataCc, this.dataBcc),
        sender: this.dataSender,
        subject: this.dataSubject,
        messageRecipientId: this.dataMessageRecipientId
      }

      m.message = {
        accountId: this.dataAccountId,
        id: this.dataMessageId,
        isConsolidated: this.dataIsConsolidated,
        isDeleted: this.dataIsDeleted,
        isRead: this.dataIsRead,
        isTreated: this.dataIsTreated,
        layout: this.dataLayout,
        marginBottom: this.dataMarginBottom,
        marginLeft: this.dataMarginLeft,
        marginRight: this.dataMarginRight,
        marginTop: this.dataMarginTop,
        marginUnit: this.dataMarginUnit,
        pageSize: this.dataPageSize
      }

      if (!this.lodash.isEqual(this.message, m)) {
        this.$emit('update:message', this.lodash.cloneDeep(m))
      }
    },
    async addContact (contact) {
      await this.$http()
        .post('/core/v6/account-contacts', {
          email: contact?.email,
          fullname: contact?.fullname?.length ? contact.fullname : contact?.email,
          isAccountContact: true,
          isActive: true,
          relatedAccounts: [{ accountId: this.accountId, contactGroupId: contact.contactGroupId }]
        }).then(() => {
          this.$store.dispatch('showSuccessSnackbar', this.$t('t.Success'))
          this.$nextTick(() => {
            this.doLoadMessage()
          })
        }).catch(e => {
          this.$store.dispatch('showErrorSnackbar', e.response.data?.message ? this.$t(e.response.data.message) : undefined)
        })
    },
    billingStatementSettingsChange (settings) {
      if (!this.selectedTemplate) return
      if (this.needConfirmation) {
        this.showConfirmApplyTemplate = true
        this.templateToApply = this.selectedTemplate
        this.cultureToApply = this.selectedCultureCode
        this.billingStatementSettingsToApply = settings
      } else {
        this.parseTemplate(this.selectedTemplate, this.selectedCultureCode, settings)
      }
    },
    cancelApplyTemplate () {
      this.showConfirmApplyTemplate = false
    },
    close () {
      recentsController.close(this.tab)
    },
    async contentTokenGenerator (token) {
      const id = 'TOKEN_001'// + (counter++);
      const tokenHtml = '<span id="' + id + '" contenteditable="true" data-token="' + token.name + '">' + this.$t(token.displayName) + '</span> '

      this.prepareForSave()
      const m = this.lodash.cloneDeep(this.dataMessage)
      m.content = tokenHtml

      const r = await this.$http().post('/accountPreviewMessage', m)
      this.insertHtmlInEditor(r.data?.content)
    },
    doApplyTemplate () {
      this.parseTemplate(this.templateToApply, this.cultureToApply, this.billingStatementSettingsToApply)
      this.showConfirmApplyTemplate = false
    },
    async downloadPDF () {
      const m = this.lodash.cloneDeep(this.dataMessage)

      m.culture = this.selectedCultureCode

      const toItems = this.dataTo.map(_ => {
        return `${_.fullname} (${_.email})`
      }).join(', ')

      const to = toItems.length > 0
        ? `${this.$t('t.To')} : ${toItems} <br />`
        : ''

      const ccItems = this.dataCc.map(_ => {
        return `${_.fullname} (${_.email})`
      }).join(', ')

      const cc = ccItems.length > 0
        ? `${this.$t('t.CC')} : ${ccItems} <br />`
        : ''

      if (this._tab.isNew) {
        m.content = !this.isEmail
          ? '<div>'
          // ? `<div>
          // ${this.$t('t.Subject')} : ${this.dataSubject}
          // <br />`
          : `<div>
          ${to}
          ${cc}
          ${this.$t('t.Subject')} : ${this.dataSubject}
          <br />
          `
        m.content += `
          ${this.computedMessageContent}
          </div>
        `
      } else {
        const fromEmail = this.dataSender.eMail
        const fromFullname = this.dataSender.fullname
        const from = `${this.$t('t.From')} : ${fromFullname} (${fromEmail}) <br />`

        m.content = !this.isEmail
          ? '<div>'
          : `<div>
          ${from}
          ${to}
          ${cc}
          ${this.$t('t.Subject')} : ${this.dataSubject}
          <br />
          `
        m.content += `
          ${this.computedMessageContent}
          </div>
          `
      }

      await this.$http()
        .post('/accountPreviewMessagePdf', m, { responseType: 'blob' })
        .then(r => {
          let filename = ''
          const disposition = r.headers['content-disposition']
          if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            const matches = filenameRegex.exec(disposition)
            if (matches !== null && matches[1]) {
              filename = matches[1].replace(/['"/]/g, '')
            }
          }

          filename = this.removeDiacritics(filename)

          const fileURL = window.URL.createObjectURL(new Blob([r.data]))
          const fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', filename)
          document.body.appendChild(fileLink)

          fileLink.click()
        })
    },
    async doLoadMessage () {
      this.dataIsEmail = 0
      this.showCC = false
      this.loading = true

      if (!this.loadMessage) {
        return
      }

      this.loadMessage().finally(() => {
        if (this.tab.templateId) {
          const t = this.templates.find(t => t.id === this.tab.templateId)
          this.selectedTemplateChange(t)
        }
        this.setTabTitle()
        this.setTabIcon(this.originalIcon)

        this.loading = false
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.form1.validate()
            this.$nextTick(() => {
              this.$emit('document-ready', this._tab)
            })
          }, 1000)
        })
      })
    },
    async doSave () {
      return this.prepareForSave().then(_ => {
        if (this.isEmail) {
          return this.save().then(() => {
            this.selectedTemplate = null
            this.billingStatementSettings = null
          })
        } else {
          return this.downloadPDF().then(() => {
            this.selectedTemplate = null
            this.billingStatementSettings = null
          })
        }
      })
    },
    editorFocused (editor) {
      this.currentContentEditor = editor
    },
    editorReady (editor) {
      this.contentEditors.push(editor)
    },
    async executeAction (action) {
      action.executeQuick(
        this.actionItem,
        isRevert => {
          if (isRevert) {
            GlobalSearch.activeSearch.refreshResults()
          } else {
            this.doLoadMessage()
            this.$router.push('/search').catch(e => {
              if (e.name !== 'NavigationDuplicated') {
                throw e
              }
            })
          }
        }
      )
    },
    generateResponse (mode) {
      this.responseMode = mode
      // mode : 0 reply, 1 reply all, 2 forward

      // Always generate response content before altering recipients or message subject
      this.processingResponse = true
      this.dataContent = this.generateResponseContent()

      if (mode === 0) { // reply
        this.dataTo = [{
          type: 0, // to
          userId: this.dataSender.userId,
          fullname: this.dataSender.fullname,
          email: this.dataSender.email,
          isAccountContact: this.dataSender.isAccountContact
        }]

        this.dataCc = []
        this.dataBcc = []
      }

      if (mode === 1) { // reply all
        const a = this.dataTo.filter(t => t.id !== this.dataMessageRecipientId)
        a.unshift({
          type: 0, // to
          userId: this.dataSender.userId,
          fullname: this.dataSender.fullname,
          email: this.dataSender.email,
          isAccountContact: this.dataSender.isAccountContact
        })

        this.dataTo = a
        this.dataCc = this.dataCc.filter(t => t.id !== this.dataMessageRecipientId)
        this.dataBcc = []
      }

      if (mode > 1) { // forward
        this.dataTo = []
        this.dataCc = []
        this.dataBcc = []
      }

      this.dataSender = {
        userId: this.currentUser.id,
        fullname: this.currentUser.fullname,
        email: this.currentUser.email,
        isAccountContact: this.currentUser.isAccountContact
      }

      if (mode <= 1) {
        this.dataAttachments = []
      }

      this.computedSubject = (mode <= 1
        ? this.$t('t.EmailResponsePrefix')
        : this.$t('t.EmailTransfertPrefix')) + ': ' + this.dataSubject

      this.readonly = false
      this.showCC = mode === 1
      this.setTabIcon(this.$icon('i.NewEmail'))

      this.dataMessageRecipientId = null

      this.dataDocumentEmit()
    },
    generateResponseContent () {
      const fromEmail = this.dataSender.email
      const fromFullname = this.dataSender.fullname
      const from = `${this.$t('t.From')} : ${fromFullname} (${fromEmail}) <br />`

      const toItems = this.dataTo.map(_ => {
        return `${_.fullname} (${_.email})`
      }).join(', ')
      const to = `${this.$t('t.To')} : ${toItems} <br />`

      const ccItems = this.dataCc.map(_ => {
        return `${_.fullname} (${_.email})`
      }).join(', ')
      const cc = `${this.$t('t.CC')} : ${ccItems} <br />`

      const sentOn = `${this.$t('t.SentOn')} : ${this.formatDateTime(this.sentOn)} <br />`

      return `
      <br />
      <br />
      <hr />
      <blockquote>
      <div>
      ${from}
      ${to}
      ${this.dataCc.length > 0 ? cc : ''}
      ${sentOn}
      ${this.$t('t.Subject')} : ${this.dataSubject}
      <br />
      ${this.computedMessageContent}
      </div>
      </blockquote>
      `
    },
    insertHtmlInEditor (value) {
      if (!this.currentContentEditor && this.contentEditors.length > 0) {
        this.currentContentEditor = this.contentEditors[0]
      }
      this.currentContentEditor.insertHtml(value, 'unfiltered_html')
    },
    async loadBillingStatementSettings (content) {
      const id = content.indexOf('BillingStatement$') !== -1
        ? content.split('BillingStatement$').pop().substring(0, 36)
        : null

      if (id) {
        const r = await this.$http().get('/billingStatementConfiguration/' + id)
        this.billingStatementSettings = r.data.settings
        if (this.invoiceMode) {
          this.billingStatementSettings.includeInvoicesCurrent = false
          this.billingStatementSettings.includeOnlyNextDueDate = false
          this.billingStatementSettings.includeInvoicesPastDue = false
          this.billingStatementSettings.excludeNegativeInvoices = false
          this.billingStatementSettings.excludePromisedInvoices = false
          this.billingStatementSettings.excludeNotPromisedInvoices = false
          this.billingStatementSettings.excludeDisputedInvoices = false
          this.billingStatementSettings.excludeNotDisputedInvoices = false
          this.billingStatementSettings.excludeAnnouncedInvoices = false
          this.billingStatementSettings.transactionTypes.forEach(function (t) {
            t.exclude = false
          })
          this.billingStatementSettings.agingBucketSet = null
        }
      }
    },
    async parseTemplate (template, targetCulture, billingStatementSettings) {
      // get the content, subject... for the target culture
      const t = lodash.find(template.templatesTranslatedValues, (_) => {
        return _.culture === targetCulture
      })

      if (!billingStatementSettings) {
        await this.loadBillingStatementSettings(t.content)
      } else {
        this.billingStatementSettings = billingStatementSettings
      }

      const m = this.lodash.cloneDeep(this.dataMessage)
      m.billingStatementSettings = this.billingStatementSettings
      m.content = t.content
      m.culture = targetCulture
      m.footer = t.footer
      m.header = t.header
      m.isConsolidated = true
      m.subject = t.subject
      m.template = template

      m.message.layout = template.layout
      m.message.marginBottom = template.marginBottom
      m.message.marginLeft = template.marginLeft
      m.message.marginRight = template.marginRight
      m.message.marginTop = template.marginTop
      m.message.marginUnit = template.marginUnit
      m.message.pageSize = template.pageSize

      const r = await this.$http().post('/accountPreviewMessage', m)
      this.dataSubject = this.responseMode === null ? r.data?.subject : this.dataSubject
      // TODO HACK to display template "as it is in v5" (prevent adding <p> tags)
      this.dataHeader = `<div>${r.data?.header ?? ''}</div>`
      this.dataContent = `<div>${r.data?.content.replace('<html><head></head><body>', '').replace('</body></html>', '') ?? ''}</div> ${this.initialResponse !== null ? this.initialResponse : ''}`
      this.dataFooter = `<div>${r.data?.footer ?? ''}</div>`
      // END

      this.dataIsConsolidated = true
      this.dataIsDunning = template.templateType === 'Reminder'
      this.dataLayout = template.layout
      this.dataMarginBottom = template.marginBottom
      this.dataMarginLeft = template.marginLeft
      this.dataMarginRight = template.marginRight
      this.dataMarginTop = template.marginTop
      this.dataMarginUnit = template.marginUnit
      this.dataPageSize = template.pageSize

      this.$triggerResize()
      this.$emit('update:tab', Object.assign(this.tab, { isPristine: false }))

      this.dataDocumentEmit()
    },
    async prepareForSave () {
      lodash.forEach(this.dataTo, (_) => {
        _.type = 0
      })
      lodash.forEach(this.dataCc, (_) => {
        _.type = 1
      })
      lodash.forEach(this.dataBcc, (_) => {
        _.type = 2
      })

      if (this.isEmail) {
        this.dataHeader = null
        this.dataFooter = null
      }

      this.dataCulture = this.selectedCultureCode
    },
    reassignMessage () {
      this.$router.push('/messages-unassigned/' + this.dataMessageId)
    },
    selectedCultureChange (culture) {
      if (!this.selectedTemplate) return
      if (this.needConfirmation) {
        this.showConfirmApplyTemplate = true
        this.templateToApply = this.selectedTemplate
        this.cultureToApply = culture
      } else {
        this.parseTemplate(this.selectedTemplate, culture)
      }
    },
    selectedTemplateChange (template) {
      this.selectedTemplate = template
      this.billingStatementSettings = null
      if (this.needConfirmation) {
        this.showConfirmApplyTemplate = true
        this.templateToApply = template
        this.cultureToApply = this.selectedCultureCode
        this.billingStatementSettingsToApply = null
      } else {
        this.parseTemplate(template, this.selectedCultureCode)
      }
    },
    setContentHeight (newHeight) {
      this.contentHeight = Math.max(86, newHeight)
    },
    setFooterHeight (newHeight) {
      this.footerHeight = Math.max(86, newHeight)
    },
    setHeaderHeight (newHeight) {
      this.headerHeight = Math.max(86, newHeight)
    },
    async setTreated (v) {
      if (!this.dataMessageRecipientId) {
        return
      }

      this.setTreatedIsRunning = true
      await this.$http()
        .post(`/core/messages/inbox/${this.dataMessageRecipientId}/mark-as-treated`, { isTreated: v })
        .then(() => {
          this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.SetProcessedConfirmation', { title: this.truncateWithEllipsis(this._tab.title), value: v ? this.$t('t.Processed') : this.$t('t.NotProcessed') }))
        })
        .catch(e => {
          this.$store.dispatch('showErrorSnackbar', this.$t('t.Snackbar.SaveError'))
        })
        .finally(() => {
          this.setTreatedIsRunning = false
        })
    },
    async setRead (v) {
      if (!this.dataMessageRecipientId) {
        return
      }

      this.setReadIsRunning = true
      const urlFlag = v === true
        ? '/mark-as-read'
        : '/mark-as-unread'

      await this.$http()
        .post(`/core/messages/inbox/${this.dataMessageRecipientId}${urlFlag}`)

        .then(() => {
          const text = v ? this.$t('t.Snackbar.SetReadConfirmation', { title: this.truncateWithEllipsis(this._tab.title) }) : this.$t('t.Snackbar.SetUnreadConfirmation', { title: this.truncateWithEllipsis(this._tab.title) })
          this.$store.dispatch('showSuccessSnackbar', text)
        })
        .catch(e => {
          this.$store.dispatch('showErrorSnackbar', this.$t('t.Snackbar.SaveError'))
        })
        .finally(() => {
          this.setReadIsRunning = false
        })
    },
    setTabIcon (icon) {
      this._tab.icon = icon
    },
    setTabTitle () {
      const t = !this.dataSubject ? this._tab.typeName : this.dataSubject
      this.$emit('document-title-change', { tab: this.tab, value: t })
    },
    async subjectTokenGenerator (token) {
      const id = 'TOKEN_001'// + (counter++);
      const tokenHtml = '<span id="' + id + '" contenteditable="true" data-token="' + token.name + '">' + this.$t(token.displayName) + '</span> '

      const m = this.lodash.cloneDeep(this.dataMessage)
      m.subject = tokenHtml

      const r = await this.$http().post('/accountPreviewMessage', m)
      const insertValue = r.data?.subject
      const textarea = this.$refs.subject_input.$refs.input
      const sentence = textarea.value
      const len = sentence.length
      let pos = textarea.selectionStart
      if (pos === undefined) {
        pos = 0
      }

      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)

      this.dataSubject = before + insertValue + after

      this.dataDocumentEmit()
    },
    toggleCC () {
      this.showCC = !this.showCC
    }
  },
  watch: {
    'tab.accountContactId': {
      async handler (id) {
        if (id) {
          const user = await getDoc(CacheType.UserRef, id)
          const accountContactToAdd = Object.assign({}, user, { fullName: user.fullname, email: user.email, isRequired: false, type: 0 })
          this.computedTo.push(accountContactToAdd)
        }
      },
      deep: false,
      immediate: true
    },
    isEmail: {
      immediate: true,
      handler (v) {
        if (!v) {
          return
        }
        this.contentEditors = []
        this.currentContentEditor = null
        this.$refs?.form1?.validate()
        this.$emit('update:tab', Object.assign(this.tab, { isPristine: false }))
      }
    },
    dataTo () {
      if (!this._tab.isLoaded) return
      this.$emit('update:tab', Object.assign(this.tab, { isPristine: false }))
    },
    dataCc () {
      if (!this._tab.isLoaded) return
      this.$emit('update:tab', Object.assign(this.tab, { isPristine: false }))
    },
    dataBcc () {
      if (!this._tab.isLoaded) return
      this.$emit('update:tab', Object.assign(this.tab, { isPristine: false }))
    },
    message: {
      immediate: true,
      handler (v) {
        this.dataAccountId = v?.message?.accountId
        this.dataAttachments = v?.attachments?.length ? this.lodash.cloneDeep(v.attachments) : []
        this.dataContent = v?.content
        this.dataCreateAt = v?.message?.createAt
        this.dataFooter = v?.footer
        this.dataHeader = v?.header
        this.dataInvoices = v?.invoices || null
        this.dataIsDeleted = v?.message?.isDeleted || false
        this.dataIsDunning = v?.isDunning || false
        this.dataIsRead = true
        this.dataIsTreated = v?.message?.isTreated || false
        this.dataLinks = v?.links?.length ? this.lodash.cloneDeep(v.links) : []
        this.dataLabels = v?.labels?.length ? this.lodash.cloneDeep(v.labels) : []
        this.dataMessage = v
        this.dataMessageId = v?.message?.id || null
        this.dataMessageRecipientId = v?.messageRecipientId || null
        this.dataSender = v?.sender
        this.dataSubject = v?.subject
        this.dataIsConsolidated = v?.message?.isConsolidated
        this.dataLayout = v?.message?.layout
        this.dataMarginBottom = v?.message?.marginBottom
        this.dataMarginLeft = v?.message?.marginLeft
        this.dataMarginRight = v?.message?.marginRight
        this.dataMarginTop = v?.message?.marginTop
        this.dataMarginUnit = v?.message?.marginUnit
        this.dataPageSize = v?.message?.pageSize

        const r = v?.recipients?.length ? this.lodash.cloneDeep(v.recipients) : []
        this.dataBcc = r?.filter(_ => _.type === 2) || []
        this.dataCc = r?.filter(_ => _.type === 1) || []
        this.dataTo = r?.filter(_ => _.type === 0) || []

        this.isBCCVisible = !!this.dataBcc.length
        this.isCCVisible = !!this.dataCc.length

        if (v) {
          this.dataSender = {
            email: v?.sender?.email,
            fullname: v?.sender?.fullname,
            userId: v?.sender?.userId,
            isAccountContact: v?.sender?.isAccountContact
          }
        }
      }
    },
    dataMessage: tabController.buildPristineWatcher()
  },
  props: {
    isUnassigned: {
      type: Boolean,
      default: false
    },
    loadMessage: Function,
    message: Object,
    save: Function,
    isOutbox: Boolean,
    tab: Object,
    invoiceDocumentTemplate: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.cultures
  min-width 150px

.full-height
  height 100% !important

.full-width
  width 100%

.main-content, .v-skeleton-loader
  margin 0.5em

.delimited
  border-top solid 1px var(--bg-plain)
  border-bottom solid 1px var(--bg-plain)

.recipients
  min-height 42px
  max-height 84px

.recipient-type
  width 36px

.email-editor
  flex-grow 1

.pdf-editor
  display grid
  flex-grow 1
  grid-template-rows 95px 1fr 95px

.pdf-editor-header
  grid-row 1

.pdf-editor-body
  grid-row 2

.pdf-editor-footer
  grid-row 3
</style>
